<template>
  <h1 id="home" class="mt-15">
    <span class="text-primary">&gt;</span> Hello, my name is <span class="text-primary">Nicky Advokaat</span>. I'm a
    <span class="text-primary">Software Engineer</span> from The Netherlands with a passion for technology. I'm a Full
    Stack developer, specialised in <span class="text-primary">Frontend</span> and <span
      class="text-primary">Mobile</span> applications.
  </h1>
</template>

<script>
export default {
  name: 'TopHeader'
}
</script>
