<template>
  <h2 id="about" class="mb-5">
    About me
  </h2>
  <p>
    I studied at Eindhoven University of Technology, where I graduated as Master of Science in Computer Science &
    Engineering in 2015. After this I started working as a consultant software developer at Ordina, during this time I
    had the opportunity to work on some really cool projects for the Dienst Justitiële Inrichten and the Dutch Police.
    Then, I worked on projects for NCIM, Fugro, and YachtCloud. Most of my projects were focused on frontend app
    development, both native and Javascript based, but I'm also very experienced in development of backend projects,
    databases, CI/CD, and automated testing. Also, I have experience as certified Scrum Master within a development
    team. <br> In 2021 I decided I was ready for the next step, and I'm now working as a freelance software engineer. Do
    you need an experienced developer on your project? Then do not hestitate to contact me!
  </p>
</template>

<script>
export default {
  name: "AboutMe"
}
</script>

<style scoped>
h2 {
  margin-top: 100px;
  text-align: center;
}
</style>